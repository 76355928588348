import React from "react";
import "../styles/App.css"

function Sports() {
    return (
        <div className="body">
            <h1>Welcome to the Sports website</h1>
            <div>
                IIKS has a number of sports teams, which include:
                <ul>
                    <li>Ski racing</li>
                    <li>Sailing</li>
                    <li>Soccer</li>
                    <li>Basketball</li>
                    <li>Cross country</li>
                    <li>Swimming</li>
                    <li>Track and field</li>
                </ul>
            </div>
        </div>
    )
}
export default Sports;