import React from "react";
import "../styles/App.css"


function NotFound() {
    return(
        <div className="body">
            <h1>404 - Not found </h1>
        </div>
    )
}
export default NotFound;